import GridBlogs from "./Blog/GridBlogs";
import Hero from "./Blog/Hero";

export default function Blog() {
  return (
    <>
      <Hero />
      <GridBlogs/>
    </>
  );
}
