import { Container } from "react-bootstrap";
import ContactForm from "./ContactFom";

export default function ContactInfo() {
  return (
    <section data-content="contact-info">
      <Container className="d-flex flex-column justify-content-center flex-md-row align-items-stretch my-4 my-lg-5">
        <Container className="info-container col-lg-5">
          <span className="d-block info-subtitle">CONTACT INFO</span>
          <span className="d-block h1">Have Questions?</span>
          <span className="d-block h1">Get in touch!</span>
          <ul className="info-list mt-4">
            <li>
              <a
                className="text-decoration-none text-muted"
                href="https://maps.app.goo.gl/dgnj9Av9duEziBP5A"
                target="_blank"
                rel="noreferrer"
              >
                <span className="d-block ms-3">
                  I block 501, Giridhari Executive Park, Peerancheru, Behind
                  Kali Mandir Hyderabad, Telangana - 500091, India.
                </span>
              </a>
            </li>
            <li>
              <a
                className="text-decoration-none text-muted"
                href="mailto:vkr@chakra-healing.in"
              >
                <span className="d-block ms-3">vkr@chakra-healing.in</span>
              </a>
            </li>
            <li>
              <a
                className="text-decoration-none text-muted"
                href="tel:+91 9177397261"
              >
                <span className="d-block ms-3">+91 91773 97261</span>
              </a>
            </li>
          </ul>
        </Container>
        <Container className="contact-form col-lg-5">
          <ContactForm />
        </Container>
      </Container>
    </section>
  );
}
