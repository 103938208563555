import { Container, Image } from "react-bootstrap";
import facebookImagOne from "./../../images/fb/one.jpg";
import facebookImagTwo from "./../../images/fb/two.jpg";
import facebookImagThree from "./../../images/fb/three.jpg";
import facebookImagFour from "./../../images/fb/four.jpg";
import facebookImagFive from "./../../images/fb/five.jpg";
import facebookImagSix from "./../../images/fb/six.jpg";

export default function GridImages() {
  return (
    <section data-content="grid-images">
      <Container fluid className="m-0 p-0 images-grid-container">
        <Container fluid className="images-container row p-0 m-0">
          <div className="col-6 col-md-4 col-lg-2 p-0 img-cont">
            <Image className="h-100 ratio ratio-1x1" src={facebookImagOne} />
          </div>
          <div className="col-6 col-md-4 col-lg-2 p-0 img-cont">
            <Image className="h-100 ratio ratio-1x1" src={facebookImagTwo} />
          </div>
          <div className="col-6 col-md-4 col-lg-2 p-0 img-cont">
            <Image className="h-100 ratio ratio-1x1" src={facebookImagThree} />
          </div>
          <div className="col-6 col-md-4 col-lg-2 p-0 img-cont">
            <Image className="h-100 ratio ratio-1x1" src={facebookImagFour} />
          </div>
          <div className="col-6 col-md-4 col-lg-2 p-0 img-cont">
            <Image className="h-100 ratio ratio-1x1" src={facebookImagFive} />
          </div>
          <div className="col-6 col-md-4 col-lg-2 p-0 img-cont">
            <Image className="h-100 ratio ratio-1x1" src={facebookImagSix} />
          </div>
        </Container>
        <Container fluid className="facebook-gallery m-0 p-0">
          <a
            className=""
            href="https://www.facebook.com/chakraheal/photos_albums"
            target="_blank"
            rel="noreferrer"
          >
            <i className="bi bi-facebook"></i>
          </a>
        </Container>
      </Container>
    </section>
  );
}
