import ContactInfo from "./ConnectWithMe/ContactInfo";
import Hero from "./ConnectWithMe/Hero";
import WhatWeDo from "./ConnectWithMe/WhatWeDo";

export default function ConnectWithMe() {
  return (
    <>
      <Hero />
      <ContactInfo />
      <WhatWeDo />
    </>
  );
}
