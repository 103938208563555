import Details from "./FreeMeditation/Details";
import Hero from "./FreeMeditation/Hero";

export default function FreeMeditations() {
  return (
    <>
      <Hero />
      <Details />
    </>
  );
}
