import { Button, Card, Container } from "react-bootstrap";
import dissolveImages from "../../images/whatif/dissolve.jpg";
import higherImages from "../../images/whatif/higher.jpg";
import realityImages from "../../images/whatif/reality.jpg";
import { Link } from "react-router-dom";

export default function WhatIf() {
  return (
    <section data-content="card">
      <h1 className="text-center text-secondary mt-5">What if</h1>
      <Container className="d-flex justify-content-center flex-column flex-lg-row justify-content-lg-between align-items-center mt-3 pt-2">
        <Card>
          <Link
            to="https://venkatasonlinehealingacademy.teachable.com"
            target="_blank"
          >
            <Card.Img
              variant="top"
              src={dissolveImages}
              alt="What if Karma can be dissolved?"
              fetchpriority="high"
              height={250}
            />
          </Link>
          <Card.Body>
            <Link
              to="https://venkatasonlinehealingacademy.teachable.com"
              target="_blank"
            >
              <Card.Title className="text-secondary">
                Karma can be dissolved
              </Card.Title>
            </Link>
            <Card.Text className="text-muted">
              If you feel that there is more to life than just surviving in your
              profession, and you are tired of the rat race then you are in the
              right place. <br className="d-none d-xl-block" />
              <span className="d-none d-xl-block">&nbsp;</span>
            </Card.Text>
            <Button
              as={Link}
              to="https://venkatasonlinehealingacademy.teachable.com"
              target="_blank"
              variant="primary"
            >
              Read more
            </Button>
          </Card.Body>
        </Card>
        <Card>
          <Link
            to="https://venkatasonlinehealingacademy.teachable.com"
            target="_blank"
          >
            <Card.Img
              variant="top"
              src={higherImages}
              height={250}
              alt="Connect to yourself?"
            />
          </Link>
          <Card.Body>
            <Link
              to="https://venkatasonlinehealingacademy.teachable.com"
              target="_blank"
            >
              <Card.Title className="text-secondary">
                Connect to Higher Self
              </Card.Title>
            </Link>
            <Card.Text className="text-muted">
              You have acquired many earthly toys, got cookies and medals as a
              reward and still feel emptiness from within then you are in the
              right place.
            </Card.Text>
            <Link
              to="https://venkatasonlinehealingacademy.teachable.com"
              target="_blank"
            >
              <Button variant="primary">Read more</Button>
            </Link>
          </Card.Body>
        </Card>
        <Card>
          <Link
            to="https://venkatasonlinehealingacademy.teachable.com"
            target="_blank"
          >
            <Card.Img
              variant="top"
              src={realityImages}
              height={250}
              alt="Bend the reality"
            />
          </Link>
          <Card.Body>
            <Link
              to="https://venkatasonlinehealingacademy.teachable.com"
              target="_blank"
            >
              <Card.Title className="text-secondary">Bend this Reality</Card.Title>
            </Link>
            <Card.Text className="text-muted">
              You have gone to every Guru and still wonder why there is no
              progress in your spiritual journey then you are in the right
              place.
              <br className="d-none d-xl-block" />
              <span className="d-none d-xl-block">&nbsp;</span>
            </Card.Text>
            <Link
              to="https://venkatasonlinehealingacademy.teachable.com"
              target="_blank"
            >
              <Button variant="primary">Read more</Button>
            </Link>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
}
