import GridCard from "./Retreats/GridCard";
import GridImages from "./Retreats/GridImages";
import Hero from "./Retreats/Hero";

export default function Retreats() {
  return (
    <>
      <Hero />
      <GridCard />
      <GridImages/>
    </>
  );
}
