import { Card, Container } from "react-bootstrap";

export default function Grid() {
  return (
    <>
      <section data-content="grid">
        <Container className="grid-container py-5">
          <div className="col-12 col-md-6 col-lg-4 card-container">
            <Card className="w-100">
              <div className="card-img-container">
                <Card.Img
                  variant="top"
                  src="https://chakra.ancorathemes.com/wp-content/uploads/2021/08/image-copyright-26-890x664.jpg"
                />
              </div>
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text className="text-muted">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <span className="btn btn-primary">
                  <span className="btn-text">Read More</span>
                  <span className="btn-icon">
                    <i className="bi bi-arrow-right"></i>
                  </span>
                </span>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-4 card-container">
            <Card className="w-100">
              <div className="card-img-container">
                <Card.Img
                  variant="top"
                  src="https://chakra.ancorathemes.com/wp-content/uploads/2021/08/image-home3-copyright-49-890x664.jpg"
                />
              </div>
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text className="text-muted">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <span className="btn btn-primary">
                  <span className="btn-text">Read More</span>
                  <span className="btn-icon">
                    <i className="bi bi-arrow-right"></i>
                  </span>
                </span>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-4 card-container">
            <Card className="w-100">
              <div className="card-img-container">
                <Card.Img
                  variant="top"
                  src="https://chakra.ancorathemes.com/wp-content/uploads/2021/08/image-copyright-25-890x664.jpg"
                />
              </div>
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text className="text-muted">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <span className="btn btn-primary">
                  <span className="btn-text">Read More</span>
                  <span className="btn-icon">
                    <i className="bi bi-arrow-right"></i>
                  </span>
                </span>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-4 card-container">
            <Card className="w-100">
              <div className="card-img-container">
                <Card.Img
                  variant="top"
                  src="https://chakra.ancorathemes.com/wp-content/uploads/2021/09/image-sub-copyright-21-890x664.jpg"
                />
              </div>
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text className="text-muted">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <span className="btn btn-primary">
                  <span className="btn-text">Read More</span>
                  <span className="btn-icon">
                    <i className="bi bi-arrow-right"></i>
                  </span>
                </span>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-4 card-container">
            <Card className="w-100">
              <div className="card-img-container">
                <Card.Img
                  variant="top"
                  src="https://chakra.ancorathemes.com/wp-content/uploads/2021/08/image-home2-copyright-34-890x664.jpg"
                />
              </div>
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text className="text-muted">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                {/* <Button variant="primary"><span className="text-hover-display">Read More</span><i className="bi bi-arrow-right"></i></Button> */}
                <span className="btn btn-primary">
                  <span className="btn-text">Read More</span>
                  <span className="btn-icon">
                    <i className="bi bi-arrow-right"></i>
                  </span>
                </span>
              </Card.Body>
            </Card>
          </div>
          <div className="col-12 col-md-6 col-lg-4 card-container">
            <Card className="w-100">
              <div className="card-img-container">
                <Card.Img
                  variant="top"
                  src="https://chakra.ancorathemes.com/wp-content/uploads/2021/08/image-copyright-9-890x664.jpg"
                />
              </div>
              <Card.Body>
                <Card.Title>Card Title</Card.Title>
                <Card.Text className="text-muted">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
                <span className="btn btn-primary">
                  <span className="btn-text">Read More</span>
                  <span className="btn-icon">
                    <i className="bi bi-arrow-right"></i>
                  </span>
                </span>
              </Card.Body>
            </Card>
          </div>
        </Container>
      </section>
    </>
  );
}
