import React from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Typed from "typed.js";
export default function Details() {
  const el = React.useRef(null);
  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "<span style='color:#000;font-weight:bold'><span class='me-1'>View Details</span><i class='bi bi-arrow-right-short'></i></span>",
        "<span style='color:#000;font-weight:bold '><span class='me-1'>View Details</span><i class='bi bi-arrow-right-short'></i></span>",
      ],
      typeSpeed: 50,
      loop: true,
      showCursor: false,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <section data-content="meditation-container">
      <Container className="my-4">
        <span className="sub-title d-block mb-2">BODY & MIND</span>
        <h2 className="mb-3">
          Increase your power of <br className="d-md-none" />
          self-healing !!!
        </h2>
        <Container className="grid-container mt-md-5 mt-xl-5 p-0">
          <div className="description-one">
            <p className="text-muted">
              You want to meditate but you don't know where to start and which
              meditation to begin with.
            </p>
          </div>
          <div className="instructor">
            <div className="py-3 py-md-0">
              <div className="row align-items-center g-4">
                <div className="col-3 col-md-12 text-center">
                  <img
                    src="https://www.filepicker.io/api/file/TF0EYkYMSS2q5kLNev4G"
                    className="img-fluid instructor-img rounded rounded-circle"
                    alt="..."
                    width={80}
                  />
                </div>
                <div className="col-9 col-md-12">
                  <div className="card-body">
                    <span className="card-title">
                      Venkata Ramana Murthy Kuruva
                    </span>
                    <p className="card-text">
                      <small className="text-body-secondary">
                        Your Instructor
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="description-two">
            <p className="text-muted">
              <span className="caps-t">T</span>here are hundrerds of them out
              there. But don't worry, I have picked the best of them which
              helped me in not just my spiritual journey but helped me heal
              hundrerds of people.
            </p>
          </div>
          <div className="description-three">
            <p className="text-muted">
              Each meditation works on different chakras. When you do these
              meditations, you will see instant results. Whether it is to
              releive the stress or amplify self-healing.
            </p>
          </div>
          <div className="description-four">
            <p className="text-muted">
              A quick start meditation program that will get you off the block
              like a pro. These three meditations are must for any one looking
              to heal himself or others. You must do them for a period of 21
              days though. Everyday, same time and same place, sit and meditate
              and see the difference.
            </p>
          </div>
        </Container>
      </Container>
      <Container fluid className="text-center py-4 bg-success">
        <Button
          as={Link}
          to="https://venkatasonlinehealingacademy.teachable.com/p/3-meditations-solve-your-problems"
          target="_blank"
          variant="secondary"
          style={{ width: "200px" }}
        >
          &nbsp;<span ref={el}></span>
        </Button>
      </Container>
    </section>
  );
}
