import { useState } from "react";

export default function Sidebar() {
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleTextClick = () => {
    setIsTextExpanded(!isTextExpanded);
  };

  return (
    <div className="sticky-sidebar" id="stickysidebar">
      <ul className="sidebar-list">
        <li
          className={`sidebar-list-item open-text first ${
            isTextExpanded ? "show" : ""
          }`}
          onClick={handleTextClick}
        >
          <span className="sidebar-icon">
            <i
              className={`bi ${
                isTextExpanded ? "bi-chevron-left" : "bi-chevron-right"
              }`}
            ></i>
          </span>
        </li>
        <li
          className={`sidebar-list-item w second ${
            isTextExpanded ? "w-170" : ""
          }`}
        >
          <a
            href="mailto:vkr@chakra-healing.in"
            className="text-light text-decoration-none"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Send Enquiry"
          >
            <span className="sidebar-icon">
              <i className="bi bi-envelope"></i>
            </span>
            <span className={`text-expand ${isTextExpanded ? "show" : ""}`}>
              Send Iquiry
            </span>
          </a>
        </li>
        <li
          className={`sidebar-list-item w third ${
            isTextExpanded ? "w-170" : ""
          }`}
        >
          <a
            href="https://wa.me/+919177397261"
            className="text-light text-decoration-none"
            target="_self"
            rel="noopener noreferrer"
            aria-label="Whatsapp"
          >
            <span className="sidebar-icon">
              <i className="bi bi-whatsapp"></i>
            </span>
            <span className={`text-expand ${isTextExpanded ? "show" : ""}`}>
              Whatsapp
            </span>
          </a>
        </li>
      </ul>
    </div>
  );
}
