import React from "react";
import { Container } from "react-bootstrap";
import Typed from "typed.js";

export default function WhatWeDo() {
  const el = React.useRef(null);
  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "<span style='color:#FF843C;font-weight:bold'>energy blockages<span>",
        "<span style='color:#FF843C;font-weight:bold '>false identities<span>",
        "<span style='color:#FF843C;font-weight:bold '>emotional release.<span>",
      ],
      typeSpeed: 100,
      loop: true,
      showCursor: false,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <section data-content="whatwedo">
      <Container fluid className="bg-success py-5 px-3">
        <Container>
          <span className="subtitle">WHAT WE DO</span>
          <p className="para fs-4">
            Worked with masters to create Chakra-Healing, 
            <br/>
            focusing on {" "}
            <span className="typing" ref={el}/> 
          </p>
          <span className="name">Venkata Ramana Murthy Kuruva</span>
          <span className="designation text-muted">Founder</span>
        </Container>
      </Container>
    </section>
  );
}
