import { Container } from "react-bootstrap";

export default function Footer() {
  return (
    <footer>
      <Container fluid className="bg-dark text-light pt-5 pb-3">
        <div className="footer-container">
          <div className="footer--map w-100">
            <iframe
              loading="lazy"
              className="w-100 rounded"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d952.0250174685682!2d78.3744033!3d17.3589171!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb96a1c15d06fd%3A0xa50270697fd7f68c!2sChakra-healing!5e0!3m2!1sen!2sin!4v1703756037890!5m2!1sen!2sin"
              height="300"
              allowFullScreen
              title="Map"
            ></iframe>
          </div>
          <div className="footer--address ps-xl-5 w-100">
            <span className="mb-2 footer-head">ADDRESS</span>
            <span>
              <a
                className="text-decoration-none text-light"
                href="https://maps.app.goo.gl/dgnj9Av9duEziBP5A"
                target="_blank"
                rel="noreferrer"
              >
                I block 501,
                <br /> Giridhari Executive Park,
                <br /> Peerancheru,
                <br /> Behind Kali Mandir Hyderabad,
                <br /> Telangana - 500091,
                <br /> India.
              </a>
            </span>
          </div>
          <div className="footer--email w-100">
            <span className="mb-2 footer-head">EMAIL</span>
            <span>
              <a
                className="text-decoration-none text-light"
                href="mailto:vkr@chakra-healing.in"
              >
                vkr@chakra-healing.in
              </a>
            </span>
          </div>
          <div className="footer--whatsapp w-100">
            <div className="w-100">
              <span className="mb-2 footer-head">CALL OR WHATSAPP</span>
              <span>
                <a
                  className="text-decoration-none text-light"
                  href="tel:+91 9177397261"
                >
                  +91 91773 97261
                </a>
              </span>
            </div>
          </div>
          <div className="footer--social w-100">
            <span className="mb-2 footer-head">SOCIAL</span>
            <span>
              <a
                className="text-decoration-none text-light"
                href="https://www.facebook.com/chakraheal"
                target="_blank"
                rel="noreferrer"
              >
                <i className="bi bi-facebook" style={{ fontSize: "24px" }}></i>
              </a>
            </span>
          </div>
        </div>
        <div className="text-center mt-3">
          <span className="align-middle">Copyright © 2024 Chakra-Healing</span>
        </div>
      </Container>
    </footer>
  );
}
