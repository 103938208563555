import { useState } from "react";
import { Container, Image, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import chakraLogoImage from  './../images/chakra.png';

export default function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };
  return (
    <Navbar fixed="top" expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand
          className="d-flex justify-content-between"
          as={Link}
          to="/"
        >
          <Image src={chakraLogoImage} alt="Chakra" />
          <span className="d-flex flex-column ms-2">
            <span>Chakra-Healing</span>
            <small className="fs-6">Where Transformation happens</small>
          </span>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
        <Navbar.Toggle
          onClick={handleNavToggle}
          className={isNavOpen ? "open" : ""}
          aria-controls="basic-navbar-nav"
        >
          <div className="icon-bar"></div>
          <div className="icon-bar"></div>
          <div className="icon-bar"></div>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="justify-content-around align-items-center w-100 fw-bold">
            <Nav.Link as={Link} to="/" className="text-dark">
              <span>Home</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/retreats" className="text-dark">
              <span>Retreats</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/blog" className="text-dark">
              <span>Blog</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/connect" className="text-dark">
              <span>Connect With Me</span>
            </Nav.Link>
            <Nav.Link as={Link} to="/meditation" className="text-dark">
              <span>Free Meditation</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
