import Hero from "./Home/Hero";
import WhatIf from "./Home/WhatIf";
import Highlights from "./Home/Highlights";
import StartHere from "./Home/StartHere";
import StudentReview from "./Home/StudentReview";

export default function Home() {
  return (
    <>
      <Hero />
      <WhatIf />
      <Highlights />
      <StartHere />
      <StudentReview />
    </>
  );
}
