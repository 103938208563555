export default function Hero() {
  return (
    <>
      <section data-content="free-meditation-bg" style={{ marginTop: "82px" }}>
        <h1 className="text-light h-100 w-100 text-center">
          <span>3 meditations : Solve your problems in 21 days</span>
          <span className="w-100 text-center">
            <i className="bi bi-chevron-down text-light h4"></i>
          </span>
        </h1>
      </section>
    </>
  );
}
