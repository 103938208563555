import React from "react";
import { Container } from "react-bootstrap";
import Typed from "typed.js";

export default function Highlights() {
  const el = React.useRef(null);
  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        "<span style='color:#FF843C;font-weight:bold'>AWAKENING THE AWARENESS<span>",
        "<span style='color:#FF843C;font-weight:bold '>AWAKENING THE AWARENESS<span>",
      ],
      typeSpeed: 50,
      loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);
  return (
    <Container
      fluid
      className="my-5 p-5"
      style={{
        backgroundColor: "#C4FCF1",
        minHeight: "150px",
        textAlign: "justify",
      }}
    >
      <div>
        <p className="text-dark">
          If you are looking for answers and ready to see through the
          illusion(s) and take responsibility of shifting your energy from being
          a victim to that of a creator then this is an invitation to join my
          signature course - If you are looking for answers and ready to see
          through the illusion(s) and take responsibility of shifting your
          energy from being a victim to that of a creator then this is an
          invitation to join my signature course - <span ref={el} />
        </p>
      </div>
    </Container>
  );
}
