import { Button, Card, Container, Modal } from "react-bootstrap";
import courseImages from "../../images/starthere/course.jpg";
import awarenessImages from "../../images/starthere/awareness.jpg";
// import { Link } from "react-router-dom";
import { useState } from "react";

export default function StartHere() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <section data-content="start-here" className="mb-4">
      <h2 className="text-secondary text-center">Start Here</h2>
      <Container className="d-md-flex justify-content-md-evenly gap-5 align-itmes-center mt-4">
        <Card className="border-0 mb-4 mb-md-0 col-12 col-md-6">
          <div className="ratio ratio-16x9">
            <Button onClick={handleShow} className="p-0">
              <Card.Img
                variant="top"
                src={courseImages}
                height={210}
                alt="Beginners Course"
                className="rounded object-fit-cover w-100 h-100"
              />
            </Button>
          </div>
          <Card.Body className="text-center">
            <Button
              variant="light-outline"
              onClick={handleShow}
              className="px-3 py-2 border-0"
            >
              <Card.Text className="text-center text-dark h4">
                Master Your Mind
              </Card.Text>
            </Button>
          </Card.Body>
        </Card>
        <Card className="border-0 col-12 col-md-6">
          <div className="ratio ratio-16x9">
            <Button onClick={handleShow} className="p-0">
              <Card.Img
                variant="top"
                height={210}
                src={awarenessImages}
                alt="Awakening your awareness"
                className="rounded object-fit-cover w-100 h-100"
              />
            </Button>
          </div>
          <Card.Body className="text-center">
            <Button
              variant="light-outline"
              className="px-3 py-2 border-0"
              onClick={handleShow}
            >
              <Card.Text className="text-center text-dark h4">
                Awakening the Awareness
              </Card.Text>
            </Button>
          </Card.Body>
        </Card>
      </Container>
      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>For Course Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="my-3 text-center">
          Please contact{" "}
          <a className="text-secondary" href="mailto:vkrmurthy@gmail.com">
            vkrmurthy@gmail.com.
          </a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}
