import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Retreats from "./pages/Retreats";
import Blog from "./pages/Blog";
import ConnectWithMe from "./pages/ConnectWithMe";
import FreeMeditations from "./pages/FreeMeditation";
import Header from "./layout/Header";
import "./styles/main.scss";
import Footer from "./layout/Footer";
import ScrollToTop from "./layout/ScrollToTop";
// import StickyBar from "./layout/StickyBar";
import Sidebar from "./layout/Sidebar";

function App() {
  return (
    // <BrowserRouter basename="/chakra">
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/retreats" element={<Retreats />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/connect" element={<ConnectWithMe />} />
        <Route path="/meditation" element={<FreeMeditations />} />
      </Routes>
      {/* <StickyBar/> */}
      <Sidebar />
      <ScrollToTop />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
