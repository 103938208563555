import { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import iamHealedImage from './../../images/iam-healed.png'
import thankYouImage from './../../images/thank-you.png'
import maginIsRealImage from './../../images/magic-is-real.png'

export default function GridBlogs() {
  const [search, setSearch] = useState("");
  const [blogItems, setBlogItems] = useState([])
  
  useEffect(() => {
    const blogList = [
      {
        category: "HEALING",
        name: "I am Healed",
        description:
          "When I talk about healing, I am talking about the complete transformation that you can experience in your being and in all areas of your life...",
        date: "Jan 01, 2024",
        comments: "16,704",
        src: iamHealedImage,
      },
      {
        category: "BELIEFS",
        name: "Thank You",
        description:
          "I cried and cried and decided I am done with me and the only solution to my problems in my life was to not have a life.But then, something happened...",
        date: "Jan 01, 2024",
        comments: "17,447",
        src: thankYouImage,
      },
      {
        category: "BELIEFS",
        name: "MAGIC is REAL",
        description:
          "What if your understanding of reality is completely false? What if you found out that every belief you thought of as a ‘law of life’ was in fact, just that – a belief?...",
        date: "Jan 01, 2024",
        comments: " 18,049",
        src: maginIsRealImage,
      },
    ];
    if (search === "" || search.length < 3) {
      setBlogItems(blogList);
    } else {
      const filterBySearch = blogList.filter((item) => {
        return (
          item.category.toLowerCase().includes(search.toLowerCase()) ||
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.description.toLowerCase().includes(search.toLowerCase())
        );
      });
      setBlogItems(filterBySearch);
    }
  }, [search]);

  return (
    <section data-content="blog">
      <Container fluid className="py-5 bg-success-light">
        <Container className="blog-position">
          <Container className="content">
            {/* <Card>
              <Link to="#" className="col-md-4">
                <Card.Img
                  variant="top"
                  src="https://heal.chakra-healing.in/wp-content/uploads/2021/03/magic-is-real-2-1024x1024.png"
                />
              </Link>
              <Card.Body>
                <Link to="#" className="d-block mb-3">
                  <Card.Text
                    className="bg-secondary d-inline-block px-3 rounded-pill text-white py-1"
                    style={{ fontSize: "x-small" }}
                  >
                    HEALING
                  </Card.Text>
                </Link>
                <Link to="#" className="d-block mb-2 text-decoration-none">
                  <Card.Subtitle className="mb-2 text-dark">
                    I am Healed
                  </Card.Subtitle>
                </Link>
                <Card.Text className="text-muted">
                  When I talk about healing, I am talking about the complete
                  transformation{" "}
                  <span className="d-none d-xxl-inline">
                    that you can experience in your being and in all areas of
                    your life
                  </span>
                  ...
                </Card.Text>
                <hr className="w-25 border border-dark opacity-100 border-1 border-bottom-0" />
                <Card.Link className="text-decoration-none text-muted" href="#">
                  <i className="bi bi-calendar-fill me-1"></i> Jan 01, 2024
                </Card.Link>
                <Card.Link className="text-decoration-none text-muted" href="#">
                  <i className="bi bi-chat-square-text-fill me-1"></i> 16,704
                  Comments
                </Card.Link>
              </Card.Body>
            </Card>
            <Card>
              <Link to="#" className="col-md-4">
                <Card.Img
                  className=""
                  variant="top"
                  src="https://heal.chakra-healing.in/wp-content/uploads/2021/03/magic-is-real-1-1024x1024.png"
                />
              </Link>
              <Card.Body>
                <Link to="#" className="d-block mb-3">
                  <Card.Text
                    className="bg-secondary d-inline-block px-3 rounded-pill text-white py-1"
                    style={{ fontSize: "x-small" }}
                  >
                    BELIEFS
                  </Card.Text>
                </Link>
                <Link to="#" className="d-block mb-2 text-decoration-none">
                  <Card.Subtitle className="mb-2 text-dark">
                    Thank You
                  </Card.Subtitle>
                </Link>
                <Card.Text className="text-muted">
                  I cried and cried and decided I am done with me and the only
                  solution{" "}
                  <span className="d-none d-xxl-inline">
                    to my problems in my life was to not have a life.But then,
                    something happened
                  </span>
                  ...
                </Card.Text>
                <hr className="w-25 border border-dark opacity-100 border-1 border-bottom-0" />
                <Card.Link className="text-decoration-none text-muted" href="#">
                  <i className="bi bi-calendar-fill me-1"></i> Jan 01, 2024
                </Card.Link>
                <Card.Link className="text-decoration-none text-muted" href="#">
                  <i className="bi bi-chat-square-text-fill me-1"></i> 17,447
                  Comments
                </Card.Link>
              </Card.Body>
            </Card>
            <Card>
              <Link to="#" className="col-md-4">
                <Card.Img
                  className=""
                  variant="top"
                  src="https://heal.chakra-healing.in/wp-content/uploads/2021/03/magic-is-real-1024x1024.png"
                />
              </Link>
              <Card.Body>
                <Link to="#" className="d-block mb-3">
                  <Card.Text
                    className="bg-secondary d-inline-block px-3 rounded-pill text-white py-1"
                    style={{ fontSize: "x-small" }}
                  >
                    BELIEFS
                  </Card.Text>
                </Link>
                <Link to="#" className="d-block mb-2 text-decoration-none">
                  <Card.Subtitle className="mb-2 text-dark">
                    MAGIC is REAL
                  </Card.Subtitle>
                </Link>
                <Card.Text className="text-muted">
                  What if your understanding of reality is completely false?
                  What if you{" "}
                  <span className="d-none d-xxl-inline">
                    found out that every belief you thought of as a ‘law of
                    life’ was in fact, just that – a belief?
                  </span>
                  ...
                </Card.Text>
                <hr className="w-25 border border-dark opacity-100 border-1 border-bottom-0" />
                <Card.Link className="text-decoration-none text-muted" href="#">
                  <i className="bi bi-calendar-fill me-1"></i> Jan 01, 2024
                </Card.Link>
                <Card.Link className="text-decoration-none text-muted" href="#">
                  <i className="bi bi-chat-square-text-fill me-1"></i> 18,049
                  Comments
                </Card.Link>
              </Card.Body>
            </Card> */}
            {blogItems.map((blog, index) => {
              return (
                <Card key={index}>
                  <Link to="#" className="col-md-4">
                    <Card.Img variant="top" src={blog.src} />
                  </Link>
                  <Card.Body>
                    <Link to="#" className="d-block mb-3">
                      <Card.Text
                        className="bg-secondary d-inline-block px-3 rounded-pill text-white py-1"
                        style={{ fontSize: "x-small" }}
                      >
                        {blog.category}
                      </Card.Text>
                    </Link>
                    <Link to="#" className="d-block mb-2 text-decoration-none">
                      <Card.Subtitle className="mb-2 text-dark">
                        {blog.name}
                      </Card.Subtitle>
                    </Link>
                    <Card.Text className="text-muted">
                      {blog.description}
                    </Card.Text>
                    <hr className="w-25 border border-dark opacity-100 border-1 border-bottom-0" />
                    <Card.Link
                      className="text-decoration-none text-muted"
                      href="#"
                    >
                      <i className="bi bi-calendar-fill me-1"></i> {blog.date}
                    </Card.Link>
                    <Card.Link
                      className="text-decoration-none text-muted"
                      href="#"
                    >
                      <i className="bi bi-chat-square-text-fill me-1"></i>{" "}
                      {blog.comments} {" "}
                      Comments
                    </Card.Link>
                  </Card.Body>
                </Card>
              );
            })}
          </Container>
          <Container className="sidebar bg-light p-4 rounded-4">
            <div className="sidebar-input mb-3">
              <label
                className="form-label"
                >
                Search
              </label>
              <input
                className="form-control me-2"
                type="search"
                placeholder="Search ..."
                aria-label="Search"
                onChange={(e) => setSearch(e.target.value)}
              ></input>
            </div>
            <div className="sidebar-categories mb-3">
              <span className="mb-2 d-block">Categories</span>
              <ul>
                <li onClick={(e) => setSearch('healing')}>Healing</li>
                <li onClick={(e) => setSearch('beliefs')}>Beliefs</li>
              </ul>
            </div>
            <div className="sidebar-posts mb-3">
              <span className="d-block mb-2">Recent Posts</span>
              <div className="card mb-3 gap-2">
                <div className="row g-0  bg-info p-3 rounded-2 align-items-center">
                  <div className="col-3">
                    <img
                      src={iamHealedImage}
                      className="img-fluid rounded-2"
                      alt="..."
                    />
                  </div>
                  <div className="col-9">
                    <div className="card-body py-0">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="card-title mb-0">I am Healed</span>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            Jan 02, 2024
                          </small>
                        </p>
                      </div>
                      <p className="card-text text-muted">
                        When I talk about healing, I am talking about...
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row g-0  bg-info p-3 rounded-2 align-items-center">
                  <div className="col-3">
                    <img
                      src={thankYouImage}
                      className="img-fluid rounded-2"
                      alt="..."
                    />
                  </div>
                  <div className="col-9">
                    <div className="card-body py-0">
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="card-title mb-0">Thank You</span>
                        <p className="card-text">
                          <small className="text-body-secondary">
                            Jan 02, 2024
                          </small>
                        </p>
                      </div>
                      <p className="card-text text-muted">
                        I cried and cried and decided I am done with me...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sidebar-tags mb-3">
              <span className="tags-heading mb-2">Tags</span>
              <span className="tags border">Healing</span>
              <span className="tags border">Beliefs</span>
            </div>
          </Container>
        </Container>
      </Container>
    </section>
  );
}
