import { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [submitMessage, setSubmitMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  // console.log(process.env.REACT_APP_RECAPTCHA_KEY);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = "Invalid email address";
    }
    if (!formData.message.trim()) {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    if (validateForm()) {
      // Form submission logic (e.g., send data to the server)
      console.log("Form submitted:", JSON.stringify(formData));

      try {
        const response = await fetch(
          "https://demo.bluekodedigital.com/chakraapi/mail.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...formData,
              recaptchaResponse: recaptchaValue,
            }),
          }
        );

        if (response.ok) {
          const result = await response.json();
          setSubmitMessage(result.message);
          setFormData({
            name: "",
            email: "",
            message: "",
          });
        } else {
          console.error("Form submission failed");
        }
      } catch (error) {
        console.error("Error during form submission:", error);
      }
    } else {
      console.log("Form has validation errors. Please correct them.");
    }
  };

  return (
    <>
      {submitMessage && (
        <p className="text-center text-primary">{submitMessage}</p>
      )}
      <form onSubmit={handleSubmit} className="px-3 mt-md-4 px-md-0 h-100">
        <FloatingLabel
          className="mb-3 text-muted name"
          controlId="floatingPassword"
          label="Name"
        >
          <Form.Control
            type="text"
            placeholder="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={errors.name ? "invalid" : ""}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingInput"
          label="Email"
          className="mb-3 text-muted email"
        >
          <Form.Control
            type="email"
            placeholder="name@example.com"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={errors.email ? "invalid" : ""}
          />
          {errors.email && <span className="error">{errors.email}</span>}
        </FloatingLabel>
        <FloatingLabel
          className="mb-3 text-muted message"
          controlId="floatingTextarea2"
          label="Message"
        >
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: "100px" }}
            name="message"
            value={formData.message}
            onChange={handleChange}
            className={errors.message ? "invalid" : ""}
          />
          {errors.message && <span className="error">{errors.message}</span>}
        </FloatingLabel>
        <div className="d-flex flex-column flex-xxl-row align-items-md-end justify-content-around align-items-center">
          <ReCAPTCHA
            size="normal"
            className="mt-3"
            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            onChange={(val) => setRecaptchaValue(val)}
          />
          <Button
            className="mb-3 mt-3 mt-md-0"
            type="submit"
            disabled={!recaptchaValue}
            variant="primary"
          >
            <i className="bi bi-send me-2"></i>Send Message
          </Button>
        </div>
      </form>
    </>
  );
}
