import { Carousel, Container, Stack } from "react-bootstrap";

export default function StudentReview() {
  return (
    <section data-content="review" className="pt-4 bg-info">
      <h3 className="text-center text-secondary">Student Reviews</h3>
      {/* <Container
        // fluid
        className="d-flex justify-content-center align-itmes-center"
      >
        <figure className="text-end">
          <blockquote className="blockquote" style={{ fontSize: "medium" }}>
            <p
              className="bg-primary p-3 rounded m-3"
              style={{ textAlign: "justify" }}
            >
              This is a course that truly Awakens – shows the Path and the
              methods are a revelation despite how much one could have read,
              learnt or practiced . It’s simple, doable and life changing . A
              Master for these times and with His teachings and guidance things
              cannot get any better. Life opens up and many wonders are
              witnessed. Thank you so much for everything
            </p>
          </blockquote>
          <figcaption className="blockquote-footer p-3 mt-2">
            <Stack
              direction="horizontal"
              style={{ flexDirection: "row-reverse" }}
              gap={3}
            >
              <span
                className="material-symbols-outlined"
                style={{ fontSize: "40px" }}
              >
                shield_person
              </span>
              <div className="vr"></div>
              <Stack className="">
                <span>Chitra</span>
                <span>
                  <span
                    className="material-symbols-outlined"
                    style={{ color: "#FF843C" }}
                  >
                    grade
                  </span>
                  <span
                    className="material-symbols-outlined"
                    style={{ color: "#FF843C" }}
                  >
                    grade
                  </span>
                  <span
                    className="material-symbols-outlined"
                    style={{ color: "#FF843C" }}
                  >
                    grade
                  </span>
                  <span
                    className="material-symbols-outlined"
                    style={{ color: "#FF843C" }}
                  >
                    grade
                  </span>
                  <span
                    className="material-symbols-outlined"
                    style={{ color: "#FF843C" }}
                  >
                    grade
                  </span>
                </span>
              </Stack>
            </Stack>
          </figcaption>
        </figure>
      </Container> */}
      <Container>
        <Carousel fade controls={false} indicators={true} variant="dark">
          <Carousel.Item interval={2000}>
            <figure className="text-end">
              <blockquote className="blockquote" style={{ fontSize: "medium" }}>
                <p
                  className="bg-light text-dark p-3 rounded m-3"
                  style={{ textAlign: "justify" }}
                >
                  This is a course that truly Awakens – shows the Path and the
                  methods are a revelation despite how much one could have read,
                  learnt or practiced . It’s simple, doable and life changing .
                  A Master for these times and with His teachings and guidance
                  things cannot get any better. Life opens up and many wonders
                  are witnessed. Thank you so much for everything
                </p>
              </blockquote>
              <figcaption className="blockquote-footer p-3 mt-2">
                <Stack
                  direction="horizontal"
                  style={{ flexDirection: "row-reverse" }}
                  gap={3}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "40px" }}
                  >
                    shield_person
                  </span>
                  <div className="vr"></div>
                  <Stack className="">
                    <span>Chitra</span>
                    <span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                    </span>
                  </Stack>
                </Stack>
              </figcaption>
            </figure>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <figure className="text-end">
              <blockquote className="blockquote" style={{ fontSize: "medium" }}>
                <p
                  className="bg-light text-dark p-3 rounded m-3"
                  style={{ textAlign: "justify" }}
                >
                  First of all Thank You for holding my hand through this
                  Awakening Awareness and Master Your Mind courses and helped to
                  find focus and joy in life. All these techniques taught by you
                  are practical and can be done by any person. If it is done by
                  consistency and sincerity. Thank you once again sir
                </p>
              </blockquote>
              <figcaption className="blockquote-footer p-3 mt-2">
                <Stack
                  direction="horizontal"
                  style={{ flexDirection: "row-reverse" }}
                  gap={3}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "40px" }}
                  >
                    shield_person
                  </span>
                  <div className="vr"></div>
                  <Stack className="">
                    <span>Vineeta</span>
                    <span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                    </span>
                  </Stack>
                </Stack>
              </figcaption>
            </figure>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <figure className="text-end">
              <blockquote className="blockquote" style={{ fontSize: "medium" }}>
                <p
                  className="bg-light text-dark p-3 rounded m-3"
                  style={{ textAlign: "justify" }}
                >
                  A course is simply put, a life-changing experience! I have
                  learnt many meditation techniques and read many books, but it
                  was only during the AA course that all my questions got
                  answered and concepts clear. Venkat sirs teaching methodology
                  is so mind-blowing that you learn even if you don’t want to😁!
                  He taught me the most purposeful techniques to live a
                  pursposeful life! 🙇‍♀️
                </p>
              </blockquote>
              <figcaption className="blockquote-footer p-3 mt-2">
                <Stack
                  direction="horizontal"
                  style={{ flexDirection: "row-reverse" }}
                  gap={3}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "40px" }}
                  >
                    shield_person
                  </span>
                  <div className="vr"></div>
                  <Stack className="">
                    <span>JD</span>
                    <span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                    </span>
                  </Stack>
                </Stack>
              </figcaption>
            </figure>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <figure className="text-end">
              <blockquote className="blockquote" style={{ fontSize: "medium" }}>
                <p
                  className="bg-light text-dark p-3 rounded m-3"
                  style={{ textAlign: "justify" }}
                >
                  This course didn’t just opened my eyes to reality but also
                  helping me awaken the one who was in hibernation mode for
                  years… Any number of Words cannot express the experience of
                  awareness that I am receiving in this course and it just goes
                  on…
                </p>
              </blockquote>
              <figcaption className="blockquote-footer p-3 mt-2">
                <Stack
                  direction="horizontal"
                  style={{ flexDirection: "row-reverse" }}
                  gap={3}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "40px" }}
                  >
                    shield_person
                  </span>
                  <div className="vr"></div>
                  <Stack className="">
                    <span>Neha</span>
                    <span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#FF843C" }}
                      >
                        grade
                      </span>
                    </span>
                  </Stack>
                </Stack>
              </figcaption>
            </figure>
          </Carousel.Item>
        </Carousel>
      </Container>
    </section>
  );
}
